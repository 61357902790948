import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const CTWrapper = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  padding: 10px 20px;
`;

const ClickTable = ({ instance, tracking_id, id }) => {
  const scriptRef = useRef(null);

  useEffect(() => {
    var s = document.createElement("script");
    s.id = "click-table";
    s.defer = true;
    s.async = true;
    s.type = "text/javascript";
    s.src = `https://${process.env.GATSBY_CT_DOMAIN}/click-table.js`;

    if (!document.getElementById(s.id)) {
      scriptRef.current.appendChild(s);
    }

    var options = {
      instance: instance,
      element: document.getElementById(id),
      mount_type: "",
    };

    if (window.ClickTable) {
      window.ClickTable.mount(options);
    } else {
      window.addEventListener("click-table-loaded", function () {
        window.ClickTable.mount(options);
      });
    }
  });

  return (
    <CTWrapper>
      <div
        ref={scriptRef}
        data-ep_position="0"
        data-ep_type="ct"
        data-shortcode="none-TNO"
        data-tracking-id={tracking_id}
        id={id}
      ></div>
    </CTWrapper>
  );
};

export default ClickTable;
